import {
	QUERY_DELETE_REPORT_KEY,
	QUERY_GET_REPORT_KEY,
	QUERY_GET_REPORT_STATUS_KEY,
	QUERY_POST_DOWNLOAD_ALL_REPORT_KEY,
	QUERY_POST_GENERATE_ROUTE_MAPS_LIST_REPORT_KEY,
	QUERY_POST_PRINT_ALL_REPORT_KEY
} from '@/const/getReport.const'
import getReportService from '@/services/getReport.service'
import {
	IDtoPostReport,
	IGenerateRouteMapsListReportParams,
	IGetReport,
	IReportParams
} from '@/types/getReport.types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export const useGetCheckReport = (params: IReportParams) =>
	useQuery({
		queryKey: [QUERY_GET_REPORT_KEY, params?.active],
		refetchInterval: 2000,
		retry: 0,
		queryFn: async () =>
			await getReportService.getAll(params).then(res => res.data)
	})

export const useGetReportStatus = (id?: number) =>
	useQuery({
		queryKey: [QUERY_GET_REPORT_STATUS_KEY],
		refetchInterval: 2000,
		enabled: !!id,
		retry: 0,
		queryFn: async () =>
			await getReportService.reportStatus(id).then(res => res.data)
	})

export const useGetNotActiveReports = (params: IReportParams) =>
	useQuery({
		queryKey: [QUERY_GET_REPORT_KEY, params?.active],
		queryFn: async () =>
			await getReportService.getAll(params).then(res => res.data)
	})

export const useGenerateRouteMapsListReport = () =>
	useMutation({
		mutationKey: [QUERY_POST_GENERATE_ROUTE_MAPS_LIST_REPORT_KEY],
		mutationFn: async (params: IGenerateRouteMapsListReportParams) =>
			await getReportService.generateReport(params).then(res => res.data)
	})

export const useDeleteCheckReport = (id?: number) =>
	useMutation({
		mutationKey: [QUERY_DELETE_REPORT_KEY, id],
		mutationFn: async (id: number) =>
			await getReportService.delete(id).then(res => res.data)
	})

export const usePrintManyRoutemaps = () =>
	useMutation({
		mutationKey: [QUERY_POST_PRINT_ALL_REPORT_KEY],
		mutationFn: async (data: IDtoPostReport) =>
			await getReportService.printAll(data).then(res => res.data)
	})

export const useDownloadRoutemap = () =>
	useMutation({
		mutationKey: [QUERY_POST_DOWNLOAD_ALL_REPORT_KEY],
		onSuccess(data: { filename: string }, variables) {
			window.location.href = data.filename
		},
		mutationFn: async (data: IDtoPostReport) =>
			await getReportService.downloadAll(data).then(res => res.data)
	})

export const useGetCheckReportActive = () => {
	const [isFirstRequest, setIsFirstRequest] = useState(true)
	const [prevState, setPrevState] = useState<IGetReport[]>([])
	const [currentState, setCurrentState] = useState<IGetReport[]>([])

	useEffect(() => {
		const interval = setInterval(fetchData, 2000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	const fetchData = async () => {
		try {
			const { data } = await getReportService.getAll({ active: true })

			if (isFirstRequest) {
				setCurrentState(data)
				setPrevState(data)
				setIsFirstRequest(false)
				return
			}

			setCurrentState(prev => {
				setPrevState(prev)
				return data
			})
		} catch (error) {
			console.error('Произошла ошибка при получении отчётов')
			console.error(error)
		}
	}

	return {
		isFirstRequest,
		current: prevState,
		previous: currentState
	}
}
