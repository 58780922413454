export const QUERY_ROUTEMAP_ALL_KEY = 'ROUTEMAP_ALL'
export const QUERY_ROUTEMAP_KEY = 'ROUTEMAP'
export const QUERY_ROUTEMAP_HISTORY_KEY = 'ROUTEMAP_HISTORY'
export const QUERY_ROUTEMAP_CREATE_KEY = 'ROUTEMAP_POST'
export const QUERY_ROUTEMAP_GET_LKK_KEY = 'ROUTEMAP_GET_LKK'
export const QUERY_OTK_PASSPORT_KEY = 'OTK_PASSPORT'
export const QUERY_ROUTEMAP_PUT_KEY = 'ROUTEMAP_PUT'
export const QUERY_ROUTEMAP_DELETE_KEY = 'ROUTEMAP_DELETE'
export const QUERY_ROUTEMAP_ALL_BY_EMPL_KEY = 'QUERY_ROUTEMAP_ALL_BY_EMPL_KEY'
export const QUERY_ROUTEMAP_SEPARATION_PUT_KEY = 'ROUTEMAP_SEPARATION_PUT'
