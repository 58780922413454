import routemapService from '../services/routemap.service'
import {
	IPostDtoRouteMap,
	IPutDtoRouteMap,
	IPutDtoRouteMapList,
	IRouteMapByEmplParams,
	IRouteMapHistoryParams,
	IRouteMapParams
} from '../types/Routemap.types'
import { QUERY_PRODUCTION_ORDER_ALL_KEY } from '@/const/productionOrder.const'
import { QUERY_PT_MASTER_REPORT_KEY } from '@/const/ptMasterReport.const'
import {
	QUERY_OTK_PASSPORT_KEY,
	QUERY_ROUTEMAP_ALL_BY_EMPL_KEY,
	QUERY_ROUTEMAP_ALL_KEY,
	QUERY_ROUTEMAP_CREATE_KEY,
	QUERY_ROUTEMAP_DELETE_KEY,
	QUERY_ROUTEMAP_GET_LKK_KEY,
	QUERY_ROUTEMAP_HISTORY_KEY,
	QUERY_ROUTEMAP_KEY,
	QUERY_ROUTEMAP_PUT_KEY,
	QUERY_ROUTEMAP_SEPARATION_PUT_KEY
} from '@/const/routemap.const'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetRoutemapAllQuery = (props?: IRouteMapParams) => {
	return useQuery({
		queryKey: [
			QUERY_ROUTEMAP_ALL_KEY,
			props?.tech_id,
			props?.name,
			props?.order_id,
			props?.pickstate,
			props?.spec_id,
			props?.incanline,
			props?.order_state,
			props?.all,
			props?.idOperation,
			props?.sbNumPart,
			props?.department_id,
			props?.empl_id,
			props?.manual_load,
			props?.packing,
			props?.production,
			props?.page,
			props?.dateFrom,
			props?.executing_programm_num,
			props?.dateTo,
			props?.status_id,
			props?.order_name,
			props?.is_active,
			props?.report_number,
			props?.exact
		],
		queryFn: async () =>
			await routemapService.getAll(props).then(res => res.data)
	})
}

export const useGetRoutemapByEmplAllQuery = (props?: IRouteMapByEmplParams) => {
	return useQuery({
		queryKey: [QUERY_ROUTEMAP_ALL_BY_EMPL_KEY, props?.empl_id],
		queryFn: async () =>
			await routemapService.getAll(props).then(res => res.data)
	})
}

export const useGetRoutemapQuery = (id?: number | null) =>
	useQuery({
		queryKey: [QUERY_ROUTEMAP_KEY, id],
		enabled: !!id && typeof id === 'number' && !isNaN(id),
		select(data) {
			return data.route_maps.length > 0 ? data.route_maps[0] : null
		},
		queryFn: async () =>
			await routemapService.getById({ id }).then(res => res.data)
	})

export const useGetRoutemapHistoryQuery = (params: IRouteMapHistoryParams) =>
	useQuery({
		queryKey: [QUERY_ROUTEMAP_HISTORY_KEY, params?.object_id, params?.page],
		enabled: !isNaN(Number(params?.object_id)),
		queryFn: async () =>
			await routemapService.getHistory(params).then(res => res.data)
	})

export const usePostRoutemapQuery = (data: IPostDtoRouteMap) =>
	useQuery<IPostDtoRouteMap, Error>({
		queryKey: [QUERY_ROUTEMAP_CREATE_KEY],
		queryFn: async () =>
			await routemapService.create(data).then(res => res.data)
	})

export const usePutRoutemapQuery = (params?: IRouteMapParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTEMAP_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_ALL_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_PT_MASTER_REPORT_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_KEY, variables?.id]
			})
		},
		mutationFn: async (data: IPutDtoRouteMap) =>
			await routemapService.update(data).then(res => res.data)
	})
}
export const usePutRoutemapListQuery = (params?: IRouteMapParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTEMAP_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_ALL_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_PT_MASTER_REPORT_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_PRODUCTION_ORDER_ALL_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_KEY]
			})
		},
		mutationFn: async (data: IPutDtoRouteMapList) =>
			await routemapService.updateMany(data).then(res => res.data)
	})
}

export const usePutRouteMapSeparationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTEMAP_SEPARATION_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_ALL_KEY]
			})
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_KEY, variables?.id]
			})
		},
		mutationFn: async (data: IPutDtoRouteMap) =>
			await routemapService.separation(data).then(res => res.data)
	})
}

export const usePostRoutemapComlateByLKKQuery = (params?: IRouteMapParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_ROUTEMAP_GET_LKK_KEY],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_ROUTEMAP_ALL_KEY]
			})
		},
		mutationFn: async ({ id }: { id: number }) =>
			await routemapService
				.complateOperationsByLKK({ id })
				.then(res => res.data)
	})
}

export const useGetOtkPassportQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_OTK_PASSPORT_KEY],
		mutationFn: async ({ id }: { id: number }) =>
			await routemapService.getOtkPassport({ id }).then(res => res.data)
	})
}

export const useDeleteRoutemapQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_ROUTEMAP_DELETE_KEY],
		queryFn: async () =>
			await routemapService.delete({ id }).then(res => res.data)
	})
