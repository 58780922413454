export const QUERY_GET_REPORT_KEY = 'QUERY_GET_REPORT_KEY'
export const QUERY_GET_REPORT_STATUS_KEY = 'QUERY_GET_REPORT_STATUS_KEY'
export const QUERY_POST_PRINT_ALL_REPORT_KEY = 'QUERY_POST_PRINT_ALL_REPORT_KEY'
export const QUERY_POST_DOWNLOAD_ALL_REPORT_KEY =
	'QUERY_POST_DOWNLOAD_ALL_REPORT_KEY'
export const QUERY_POST_PRINT_BY_ID_REPORT_KEY =
	'QUERY_POST_PRINT_BY_ID_REPORT_KEY'
export const QUERY_DELETE_REPORT_KEY = 'QUERY_DELETE_REPORT_KEY'
export const QUERY_POST_GENERATE_ROUTE_MAPS_LIST_REPORT_KEY =
	'QUERY_POST_GENERATE_ROUTE_MAPS_LIST_REPORT'
